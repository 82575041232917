import styled from "styled-components";

export const BurgerStyled = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100vh;
  transform: translateY(-100%);
  transition: all var(--transition);
  z-index: 9;
  background-color: white;
  opacity: 0;
  padding: 120px 0 20px;

  &.enter-done {
    transform: translateY(0);
    opacity: 1;
  }

  & .container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;

    & .burger-nav {
      flex-direction: column;
      gap: 12px;

      & .active {
        color: var(--accent);
        pointer-events: none;
      }
    }
  }
`;
