import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--color);
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

export const InputLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all var(--transition);

  @media screen and (width < 720px) {
    font-size: 14px;
  }
`;

export const InputStyled = styled.input`
  font-family: inherit;
  outline: transparent;
  box-sizing: border-box;
  background: transparent;
  border: none;
  padding: 10px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: var(--color);

  @media screen and (width < 720px) {
    font-size: 16px;
  }

  &::placeholder {
    opacity: 0.75;
  }

  &:focus,
  &:not([value=""]) {
    & ~ label {
      top: -5px;
      left: 0;
      font-size: 12px;

      @media screen and (width < 720px) {
        font-size: 10px;
      }
    }
  }
`;
