import styled from "styled-components";

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  position: relative;

  &::before {
    content: "";
    width: 100%;
    height: 2px;
    background-color: var(--color);
    border-radius: 20px;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  & .PhoneInput {
    font-family: inherit;
    outline: transparent;
    box-sizing: border-box;
    background: transparent;
    padding: 0 0 0 10px;
    width: 100%;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: var(--color);
    overflow: hidden;
    display: grid;
    grid-template-columns: 53px 1fr;

    & .PhoneInputCountryIcon {
      overflow: hidden;
      height: 20px;
      width: 30px;
      box-shadow: none;
    }

    & input {
      font-family: inherit;
      font-weight: 500;
      border: none;
      outline: none;
      padding: 10px 0;
      background: transparent;
      width: 100%;

      &::placeholder {
        opacity: 0.75;
      }
    }
  }
`;

export const InputLabel = styled.label`
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  padding-left: 24px;
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);
  pointer-events: none;
  transition: all var(--transition);

  @media screen and (width < 720px) {
    font-size: 14px;
  }

  &.focus {
    top: -5px;
    left: 35px;
    font-size: 12px;

    @media screen and (width < 720px) {
      font-size: 10px;
    }
  }
`;

export const InputStyled = styled.input`
  font-family: inherit;
  outline: transparent;
  box-sizing: border-box;
  background: transparent;
  border: 1px solid #cccccc;
  border-radius: 40px;
  padding: 16px 24px;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #595959;

  &::placeholder {
    opacity: 0.75;
  }
`;
