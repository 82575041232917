import React, { useRef, useEffect } from "react";

const StarField = () => {
  const canvasRef = useRef(null);
  const parallaxIntensity = 0.1;

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    canvas.width = window.innerWidth;
    canvas.height = window.innerHeight;

    let mouseX = 0;
    let mouseY = 0;

    class Star {
      constructor(x, y, z, color) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.color = color;
      }

      update() {
        this.z -= 0.5;
        if (this.z < 1) {
          this.z = canvas.width;
          this.x = Math.random() * canvas.width - canvas.width / 2;
          this.y = Math.random() * canvas.height - canvas.height / 2;
          this.color = this.getRandomColor();
        }
      }

      draw() {
        const parallaxX = (mouseX - canvas.width / 2) * parallaxIntensity * -1;
        const parallaxY = (mouseY - canvas.height / 2) * parallaxIntensity * -1;

        const sx =
          (this.x / this.z) * canvas.width + canvas.width / 2 + parallaxX;
        const sy =
          (this.y / this.z) * canvas.height + canvas.height / 2 + parallaxY;
        const radius = (canvas.width / this.z) * 0.5;

        ctx.beginPath();
        ctx.arc(sx, sy, radius, 0, Math.PI * 2, false);
        ctx.fillStyle = this.color;
        ctx.fill();
        ctx.closePath();
      }

      getRandomColor() {
        const colors = [
          "#FF5733",
          "#33FF57",
          "#3357FF",
          "#FF33A8",
          "#33FFF6",
          "#FFDB33",
        ];
        return colors[Math.floor(Math.random() * colors.length)];
      }
    }

    const stars = [];
    for (let i = 0; i < 1000; i++) {
      const x = Math.random() * canvas.width - canvas.width / 2;
      const y = Math.random() * canvas.height - canvas.height / 2;
      const z = Math.random() * canvas.width;
      const color = new Star().getRandomColor();
      stars.push(new Star(x, y, z, color));
    }

    const animate = () => {
      requestAnimationFrame(animate);
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      stars.forEach((star) => {
        star.update();
        star.draw();
      });
    };

    animate();

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const handleMouseMove = (e) => {
      if (window.innerWidth >= 1280) {
        mouseX = e.clientX;
        mouseY = e.clientY;
      }
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        maxWidth: "100%",
        maxHeight: "100%",
      }}
    />
  );
};

export default StarField;
