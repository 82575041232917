import { useInView } from "react-intersection-observer";
import { Hero } from "./Hero/Hero.jsx";
import { MarketLine } from "./MarketLine/MarketLine.jsx";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setSection } from "../../redux/app/slice.js";

const About = () => {
  const dispatch = useDispatch();

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    inView && dispatch(setSection("about"));
  }, [dispatch, inView]);

  return (
    <div ref={ref}>
      <Hero />
      <MarketLine />
    </div>
  );
};

export default About;
