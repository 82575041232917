import styled from "styled-components";

export const Section = styled.section`
  background-color: transparent;
  color: var(--body-background);
  min-height: 100vh;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 100%;

  @media screen and (width < 720px) {
    padding: 50px 0;
  }

  & .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 50px;
  }
`;

export const Content = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;

  & > img {
    filter: drop-shadow(0px -5px 25px var(--accent));

    @media screen and (width < 720px) {
      width: 40px;
      height: 40px;
    }
  }

  & > h1 {
    font-family: Neuropolitical;
    font-size: 74px;
    text-transform: uppercase;
    font-weight: 400;
    text-align: center;

    @media screen and (width < 720px) {
      font-size: 36px;
    }
  }

  & > p {
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    max-width: 1000px;

    @media screen and (width < 720px) {
      font-size: 14px;
    }
  }
`;

export const Foot = styled.ul`
  display: grid;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  gap: 50px;
  max-width: 100%;
  grid-template-columns: repeat(4, 1fr);
  padding: 0 200px;

  @media screen and (width < 720px) {
    margin-top: 0;
    gap: 30px 15px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    place-items: center;
    padding: 0 10px;
  }

  & > li {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: all 500ms ease-in-out;
    width: fit-content;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      width: 20px;
      height: 3px;
      top: 5px;
      left: -5px;
      background-color: red;
      border-radius: 10px;
    }

    &::after {
      content: "";
      position: absolute;
      width: 20px;
      height: 3px;
      top: 10px;
      left: -10px;
      background-color: red;
      border-radius: 10px;
    }

    & > p {
      font-size: 20px;
      text-transform: uppercase;
      z-index: 5;

      @media screen and (width < 720px) {
        font-size: 14px;
      }
    }
  }
`;

export const DecorCircleText = styled.div`
  width: 170px;
  height: 170px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(160deg, #eda358 11%, #cd3f5a 49%, #7d9243 100%);

  & > p {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;

    @media screen and (width < 720px) {
      font-size: 14px;
    }
  }
`;

export const StarContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background-color: #000;
  pointer-events: none;
  overflow: hidden;
`;
