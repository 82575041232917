import About from "./about/About.jsx";
import Contact from "./contact/Contact.jsx";
import Home from "./home/Home.jsx";

export const Page = () => {
  return (
    <>
      <Home />
      <About />
      <Contact />
    </>
  );
};
