import styled from "styled-components";
import img from "../../../img/Texture.png";

export const Section = styled.section`
  background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url(${img});
  position: sticky;
  top: 0;
  left: 0;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 0;
  min-height: 100vh;

  & .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  position: relative;

  background-color: transparent;

  & img {
    z-index: 2;
  }

  & h1 {
    font-family: Neuropolitical;
    font-size: 74px;
    font-weight: 400;
    z-index: 2;

    @media screen and (width < 720px) {
      font-size: 36px;
    }
  }

  & .desc {
    font-family: Sansation;
    font-size: 24px;
    letter-spacing: 0.4em;
    z-index: 2;

    @media screen and (width < 720px) {
      font-size: 16px;
    }
  }

  & .info {
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    z-index: 2;
    max-width: 500px;

    @media screen and (width < 720px) {
      font-size: 14px;
    }
  }

  & span {
    display: block;
    border-radius: 50%;
    position: absolute;
    border: 1px solid red;
    transition: all var(--transition);
    pointer-events: none;

    &.span-one {
      top: 0;
      left: -15%;
      width: 700px;
      height: 700px;
      background: linear-gradient(0deg, #f58a2566 20%, #f22fb0 100%);
      filter: blur(50px);
      -webkit-filter: blur(50px);
      animation: moveone 15s infinite ease-in-out;

      @media screen and (width < 720px) {
        width: 300px;
        height: 300px;
        animation: moveoneMobile 15s infinite ease-in-out;
      }
    }

    &.span-two {
      top: 0%;
      left: 30%;
      width: 600px;
      height: 600px;
      background: linear-gradient(0deg, #f22fb099 20%, #f58a25bb 120%);
      z-index: 1;
      filter: blur(50px);
      -webkit-filter: blur(50px);
      animation: movetwo 15s infinite ease-in-out;

      @media screen and (width < 720px) {
        width: 300px;
        height: 300px;
        animation: movetwoMobile 15s infinite ease-in-out;
      }
    }

    @keyframes moveoneMobile {
      0% {
        transform: translate(0, 15%);
        background: #f58a2566;
      }
      25% {
        transform: translate(10%, 15%);
        background: #f22fb066;
      }
      50% {
        transform: translate(25%, 0);
        background: #f58a2566;
      }
      75% {
        transform: translate(10%, 5%);
        background: #f22fb066;
      }
      100% {
        transform: translate(0, 15%);
        background: #f58a2566;
      }
    }

    @keyframes movetwoMobile {
      0% {
        transform: translate(20%, 20%);
      }
      25% {
        transform: translate(10%, -15%);
      }
      50% {
        transform: translate(20%, 10%);
      }
      75% {
        transform: translate(30%, -20%);
      }
      100% {
        transform: translate(20%, 20%);
      }
    }

    @keyframes moveone {
      0% {
        transform: translate(0, 0);
        background: #f58a2566;
      }
      25% {
        transform: translate(-10%, -10%);
        background: #f22fb066;
      }
      50% {
        transform: translate(-5%, -5%);
        background: #f58a2566;
      }
      75% {
        transform: translate(-10%, -5%);
        background: #f22fb066;
      }
      100% {
        transform: translate(0, 0);
        background: #f58a2566;
      }
    }

    @keyframes movetwo {
      0% {
        transform: translate(-5%, 10%);
      }
      25% {
        transform: translate(-10%, -5%);
      }
      50% {
        transform: translate(-5%, 0);
      }
      75% {
        transform: translate(0, 5%);
      }
      100% {
        transform: translate(-5%, 10%);
      }
    }
  }
`;
