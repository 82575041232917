import { useLanguage } from "../../hooks/useLanguage.js";
import { useSection } from "../../hooks/useSection.js";
import { handleScroll } from "../../other.js";
import { Button } from "./NavMenu.styled.js";

export const NavMenu = ({ className }) => {
  const {
    schema: { nav },
  } = useLanguage();
  const section = useSection();

  return (
    <ul className={className}>
      {nav?.map(({ link, label }, index) => {
        return (
          <li key={index}>
            <Button
              className={section === link ? "active" : ""}
              onClick={() => handleScroll(link)}
            >
              {label}
            </Button>
          </li>
        );
      })}
    </ul>
  );
};
