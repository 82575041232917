import { Outlet } from "react-router-dom";
import { LayoutStyled, Main } from "./Layout.styled";
import Header from "../Header/Header";
import { setIsOpenBurger } from "../../redux/app/slice";
import { useEffect, useState } from "react";
import { useBurger } from "../../hooks/useBurger";
import { useDispatch } from "react-redux";
import { Burger } from "../Burger/Burger.jsx";

const Layout = () => {
  const { isOpen } = useBurger();
  const dispatch = useDispatch();
  const [isOpenBurgerState, setIsOpenBurgerState] = useState(isOpen);
  const [isEnterDone, setIsEnterDone] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setIsOpenBurgerState(true);
      setTimeout(() => setIsEnterDone(true), 100);
    } else {
      setIsEnterDone(false);
      setTimeout(() => setIsOpenBurgerState(false), 100);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return;
    }
    document.body.style.overflow = "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  useEffect(() => {
    const func = (e) => {
      const {
        target: { innerWidth },
      } = e || { target: {} };
      if (innerWidth >= 1280) {
        dispatch(setIsOpenBurger(false));
      }
    };
    window.addEventListener("resize", func);

    return () => window.removeEventListener("resize", func);
  }, [dispatch]);

  return (
    <LayoutStyled>
      <Header />
      <Main>
        <Outlet />
      </Main>
      {isOpenBurgerState && <Burger className={isEnterDone && "enter-done"} />}
    </LayoutStyled>
  );
};

export default Layout;
