import styled from "styled-components";

export const ContainerStyled = styled.div`
  padding: 0 26px;
  position: relative;
  margin: 0 auto;
  display: block;
  height: 100%;
  width: 100%;

  @media screen and (width > 1360px) {
    padding: 0;
  }

  @media screen and (width >= 1280px) {
    max-width: 1280px;
  }

  @media screen and (width < 1280px) and (width >= 720px) {
    max-width: 720px;
    padding: 0 15px;
  }

  @media screen and (width < 720px) {
    max-width: 420px;
    width: 100%;
    padding: 0 10px;
  }
`;
