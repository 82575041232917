import { Container } from "../../../components/Container/Container.jsx";
import { useLanguage } from "../../../hooks/useLanguage.js";
import { Content, Section } from "./Hero.styled.js";
import img from "../../../img/logo-color.svg";
import gsap from "gsap";
import { useEffect, useRef } from "react";
import { LinkButton } from "../../../components/LinkButton/LinkButton.jsx";

export const Hero = () => {
  const {
    schema: {
      home: {
        hero: { title, desc, info },
      },
    },
  } = useLanguage();

  const nodeRef = useRef(null);

  useEffect(() => {
    const elements = gsap.utils.toArray(
      nodeRef.current.querySelectorAll(".gsap")
    );

    const spans = gsap.utils.toArray(nodeRef.current.querySelectorAll(".span"));

    gsap.from(elements, {
      duration: 1.8,
      delay: 0,
      stagger: 0.1,
      y: "-70px",
      z: "-30px",
      rotationX: -75,
      opacity: 0,
      ease: "power4.out",
    });

    gsap.from(spans, {
      duration: 4,
      stagger: 0,
      opacity: 0,
      ease: "power4.out",
    });
  }, []);

  return (
    <Section id="home">
      <Container>
        <Content ref={nodeRef}>
          <img className="gsap" src={img} alt="logo" width={90} height={90} />
          <h1 className="gsap">{title}</h1>
          <p className="desc gsap">{desc}</p>
          <p className="info gsap">{info}</p>

          <span className="span span-one" />
          <span className="span span-two" />

          <LinkButton className={"gsap"} link="contact" text="CONTACT US NOW" />
        </Content>
      </Container>
    </Section>
  );
};
