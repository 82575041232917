import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { InputContainer, InputLabel } from "./CustomPhoneNumberInput.styled";
import { useState } from "react";

export const CustomPhoneNumberInput = ({
  id,
  label,
  placeholder,
  value,
  name,
  onChange,
  defaultCountry = "GB",
  isRequired = false,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  return (
    <InputContainer>
      <PhoneInput
        placeholder={placeholder}
        value={value}
        defaultCountry={defaultCountry}
        onChange={onChange}
        id={id}
        name={name}
        onFocus={() => setIsFocus(true)}
        onBlur={() => {
          if (value) return;
          setIsFocus(false);
        }}
      />
      {label && (
        <InputLabel className={isFocus ? "focus" : ""} htmlFor={id}>{`${label}${
          isRequired ? " *" : ""
        }`}</InputLabel>
      )}
    </InputContainer>
  );
};
