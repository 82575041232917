import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  width: 550px;

  @media screen and (width < 720px) {
    width: 100%;
    gap: 30px;
  }

  & > h3 {
    font-size: 24px;
    font-weight: 500;
    font-family: Neuropolitical;

    @media screen and (width < 720px) {
      font-size: 18px;
    }
  }
`;

export const SubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: linear-gradient(90deg, #d761a5 0%, #55a4e6 100%);
  padding: 15px 30px;
  border-radius: 12px;
  color: #fff;
  border: none;
  outline: none;

  & svg {
    fill: currentColor;
  }

  &:disabled {
    cursor: not-allowed;
    background: gray;
    color: rgba(255, 255, 255, 0.5);
  }

  @media screen and (width < 720px) {
    font-size: 16px;
    padding: 12px 20px;
  }

  &:not(:disabled) {
    @media (hover: hover) {
      &:hover {
        box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
      }
    }
  }
`;

export const RowContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px;
  width: 100%;

  @media screen and (width < 720px) {
    gap: 30px;
    grid-template-columns: 1fr;
  }
`;
