import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
     :root {
          --transition: 200ms ease;
          --body-background: #D9D9D9;
          --color: #000;
          --accent: #f58a25;
          --second-accent: #11b4b3;
          --border-radius: 40px
     }


     body {
          font-family: 'Fixel', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
          'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
          sans-serif;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          background-color: var(--body-background);
          color: var(--color);
          fill: var(--color);

     }    

     code {
          font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
          monospace;
     }

     input::-webkit-outer-spin-button,
     input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
     }

     svg {
          fill: inherit;
          stroke: inherit
     }
     
`;
