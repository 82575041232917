import { BurgerStyled } from "./Burger.styled";
import { Container } from "../Container/Container";

export const Burger = ({ className }) => {
  return (
    <BurgerStyled className={className}>
      <Container></Container>
    </BurgerStyled>
  );
};
