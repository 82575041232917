import styled from "styled-components";
import img from "../../img/Texture.png";

export const Section = styled.section`
  min-height: 100vh;
  background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url(${img});
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 80px 0;

  & .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 80px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;

  & > h2 {
    font-family: Neuropolitical;
    font-size: 50px;
    font-weight: 400;
    text-align: center;

    @media screen and (width < 720px) {
      font-size: 24px;
    }
  }

  & > p {
    max-width: 500px;
    text-align: center;
    font-size: 16px;
    line-height: 1.4em;

    @media screen and (width < 720px) {
      font-size: 14px;
    }
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
