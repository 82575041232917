import { useLanguage } from "../../../hooks/useLanguage.js";
import { ScrollContainer, Section } from "./MarketLine.styled.js";

export const MarketLine = () => {
  const {
    schema: {
      about: {
        market: { list },
      },
    },
  } = useLanguage();

  const renderList = (list) => {
    return list.map((text, index) => (
      <div key={index}>
        <p>{text}</p>
      </div>
    ));
  };

  return (
    <Section>
      <ScrollContainer>
        <div>
          {renderList(list)}
          {renderList(list)}
        </div>
      </ScrollContainer>
    </Section>
  );
};
