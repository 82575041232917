import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: "app",
  initialState: {
    header: {
      isOpen: true,
      isBlack: false,
    },
    burger: {
      isOpen: false,
    },
    section: "home",
    language: "en",
  },
  reducers: {
    setIsOpenHeader(state, { payload }) {
      state.header.isOpen = payload;
    },
    setIsBlackHeader(state, { payload }) {
      state.header.isBlack = payload;
    },
    setLaguage(state, { payload }) {
      state.language = payload;
    },
    setIsOpenBurger(state, { payload }) {
      state.burger.isOpen = payload;
    },
    setSection(state, { payload }) {
      state.section = payload;
    },
  },
});

export const appReducer = appSlice.reducer;
export const {
  setIsOpenHeader,
  setLaguage,
  setIsOpenBurger,
  setIsBlackHeader,
  setSection,
} = appSlice.actions;
