import styled from "styled-components";

export const LayoutStyled = styled.div`
  display: grid;
  position: relative;
  gap: 20px;

  min-height: 100vh;

  &.burger-open {
    overflow: hidden;
  }
`;

export const Main = styled.main`
  width: 100%;
`;
