import styled from "styled-components";
import img from "../../../img/Texture.png";
import { Parallax } from "react-scroll-parallax";

export const Section = styled.section`
  background: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.75),
      rgba(255, 255, 255, 0.75)
    ),
    url(${img});
  overflow: hidden;

  & .container {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
  }
`;

export const ScreenContainer = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  position: relative;
  width: 100%;

  & h1 {
    font-family: Neuropolitical;
    font-size: 74px;
    font-weight: 400;
    z-index: 2;
    text-transform: uppercase;
    text-align: center;

    @media screen and (width < 720px) {
      font-size: 36px;
    }
  }

  & .desc {
    font-family: Sansation;
    font-size: 24px;
    letter-spacing: 0.3em;
    z-index: 2;
    text-transform: uppercase;
    text-align: center;

    @media screen and (width < 720px) {
      font-size: 16px;
      letter-spacing: 0.2em;
    }
  }

  & .info {
    font-size: 16px;
    line-height: 1.5em;
    text-align: center;
    z-index: 2;
    max-width: 700px;

    @media screen and (width < 720px) {
      font-size: 14px;
      line-height: 1.3em;
    }
  }
`;

export const AnimaContainer = styled.div`
  position: absolute;
  top: -90%;
  left: -18%;
  opacity: 0.25;

  @media screen and (width < 720px) {
    top: -20%;
    left: 0;
  }

  & svg {
    @media screen and (width < 720px) {
      width: 100%;
      height: auto;
    }
  }
`;

export const List = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 85px;

  @media screen and (width < 720px) {
    gap: 40px;
  }

  & > li {
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-width: 50%;

    @media screen and (width < 720px) {
      max-width: 100%;
    }

    & > h3 {
      font-size: 36px;
      font-weight: 500;

      @media screen and (width < 720px) {
        font-size: 24px;
      }
    }

    & > p {
      font-size: 16px;
      line-height: 1.4em;

      @media screen and (width < 720px) {
        font-size: 14px;
      }
    }
  }
`;

export const Decor = styled(Parallax)`
  position: absolute;
  border: 1px solid red;
  border-radius: 50%;

  &.one {
    top: 70%;
    left: 0;
    width: 200px;
    height: 200px;
    background: url(${img}),
      linear-gradient(
          160deg,
          #7d40ffaa 40%,
          #f58a25ff 100%,
          rgba(255, 255, 255, 0.1)
        )
        100%;
    filter: blur(30px);
    -webkit-filter: blur(30px);

    @media screen and (width < 720px) {
      width: 100px;
      height: 100px;
      filter: blur(25px);
      -webkit-filter: blur(25px);
    }
  }

  &.two {
    top: -10%;
    left: 0;
    width: 700px;
    height: 700px;
    background: url(${img}),
      linear-gradient(
          0deg,
          #42ddffaa 0%,
          #2eb0ffaa 78%,
          #1170ff88 100%,
          rgba(255, 255, 255, 0.1)
        )
        100%;
    filter: blur(70px);
    -webkit-filter: blur(70px);

    @media screen and (width < 720px) {
      width: 300px;
      height: 300px;
      filter: blur(50px);
      -webkit-filter: blur(50px);
    }
  }

  &.three {
    top: 0;
    left: 70%;
    width: 300px;
    height: 300px;
    background: linear-gradient(
        -55deg,
        #f22fb0aa 40%,
        #7061a3ff 90%,
        rgba(255, 255, 255, 0.1)
      )
      100%;
    filter: blur(40px);
    -webkit-filter: blur(40px);

    @media screen and (width < 720px) {
      width: 150px;
      height: 150px;
      filter: blur(30px);
      -webkit-filter: blur(30px);
    }
  }

  .element {
    position: absolute;
    animation: rotateOne 5s linear infinite;
  }

  .element {
    position: absolute;
    animation: rotateOne 5s linear infinite;
  }
`;

export const InfoContainer = styled.div`
  margin-bottom: 200px;
  position: relative;

  @media screen and (width < 720px) {
    margin-bottom: 80px;
  }
`;

export const DecorContainer = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 50%;
  height: 100%;

  @media screen and (width < 720px) {
    width: 300px;
    height: 300px;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  & > .decor {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @media screen and (width < 720px) {
      width: 300px;
      height: 300px;
    }

    &.one {
      animation: rotate 20s linear infinite;
    }

    &.two {
      animation: rotate 40s linear infinite;
    }
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const MissionContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 200px;

  & h2 {
    font-family: Neuropolitical;
    font-size: 50px;
    font-weight: 400;
    text-align: center;

    @media screen and (width < 720px) {
      font-size: 24px;
    }
  }

  & p {
    text-align: center;
    font-size: 16px;
    max-width: 550px;
    line-height: 1.4em;

    @media screen and (width < 720px) {
      font-size: 14px;
    }
  }
`;
