import { Container } from "../../../components/Container/Container.jsx";
import { Content, Foot, Section, StarContainer } from "./Meet.styled.js";
import img from "../../../img/logo-white.svg";
import { useLanguage } from "../../../hooks/useLanguage.js";
import StarField from "../../../components/StarField/StarField.jsx";

export const Meet = () => {
  const {
    schema: {
      home: {
        meet: { title, desc, list },
      },
    },
  } = useLanguage();

  return (
    <Section>
      <StarContainer>
        <StarField />
      </StarContainer>
      <Container>
        <Content>
          <img
            src={img}
            alt="logo"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          />
          <h1 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {title}
          </h1>
          <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {desc}
          </p>
          <Foot data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {list.map((text, index) => {
              return (
                <li key={index}>
                  <p>{text}</p>
                </li>
              );
            })}
          </Foot>
        </Content>
      </Container>
    </Section>
  );
};
