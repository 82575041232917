import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";
import { setIsOpenHeader } from "../../redux/app/slice";
import { HeaderStyled } from "./Header.styled";
import { Container } from "../Container/Container";
import clsx from "clsx";
import { useBurger } from "../../hooks/useBurger";
import { NavMenu } from "../NavMenu/NavMenu.jsx";
import gsap from "gsap";
import { useHeader } from "../../hooks/useHeader.js";

const Header = () => {
  const dispatch = useDispatch();
  const [isScroll, setIsScroll] = useState(false);
  const { isOpen } = useBurger();
  const { isBlack, isOpen: isOpenHeader } = useHeader();

  const nodeRef = useRef(null);

  useEffect(() => {
    const menu = nodeRef.current.querySelector(".nav-menu");
    gsap.from([menu], {
      duration: 1.8,
      delay: 0,
      stagger: 0.18,
      y: "-170px",
      ease: "power4.out",
    });
  }, []);

  let lastScrollTop = useRef(0);

  const handleScroll = useCallback(() => {
    let scrollTop = window.scrollY || document.documentElement.scrollTop;
    let tempScroll = scrollTop - lastScrollTop.current;

    let tempUp = -20;
    let tempDown = 30;

    if (!isOpenHeader && scrollTop <= 50) {
      dispatch(setIsOpenHeader(true));
      return;
    }

    if (tempScroll < 0) {
      // scroll up
      if (!isOpenHeader && tempScroll < tempUp) {
        dispatch(setIsOpenHeader(true));
      }

      if (scrollTop <= 10 && isScroll) {
        setIsScroll(false);
      }
    } else {
      //scroll down
      if (scrollTop >= 80 && !isScroll) {
        setIsScroll(true);
      }

      if (scrollTop >= 100 && isOpenHeader && tempScroll > tempDown) {
        dispatch(setIsOpenHeader(false));
      }
    }

    lastScrollTop.current = scrollTop;
  }, [dispatch, isOpenHeader, isScroll]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return (
    <HeaderStyled
      ref={nodeRef}
      className={clsx(
        "header",
        isScroll && "scroll",
        isOpen && "burger-open",
        isBlack && "black",
        !isOpenHeader && "hidden"
      )}
    >
      <Container>
        <NavMenu className={"nav-menu"} />
      </Container>
    </HeaderStyled>
  );
};

export default Header;
