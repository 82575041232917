import { svg } from "../../img/index.js";
import { handleScroll } from "../../other.js";
import { Container, Content, LinkStyled } from "./LinkButton.styled.js";

export const LinkButton = ({ link = "home", text = "", className }) => {
  return (
    <Container className={className}>
      <LinkStyled onClick={() => handleScroll(link)}>
        <Content>
          <p>{text}</p>
          <svg width={20} height={20}>
            <use href={`${svg}#icon-mail`} />
          </svg>
        </Content>
      </LinkStyled>
    </Container>
  );
};
