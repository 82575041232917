import styled from "styled-components";

export const Section = styled.section`
  width: 100%;
  background-color: var(--color);
  color: var(--body-background);
  display: flex;
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    width: 50px;
    height: 100%;
    position: absolute;
    left: -50px;
    top: 0;
    box-shadow: 0 0 50px 35px #000;
    z-index: 1;
  }

  &::after {
    content: "";
    width: 50px;
    height: 100%;
    position: absolute;
    right: -50px;
    top: 0;
    box-shadow: 0 0 50px 35px #000;
    z-index: 1;
  }
`;

export const ScrollContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  position: relative;
  height: 100%;
  padding: 20px 0;

  & > div {
    display: flex;
    min-width: 200%;
    height: 100%;
    animation: scroll 20s linear infinite;

    & > div {
      width: calc(100% / 4);
      display: flex;
      justify-content: center;
      align-items: center;

      @media screen and (width < 720px) {
        width: calc(100% / 3);
      }

      & > p {
        text-align: center;
        font-size: 32px;
        text-transform: uppercase;
        font-weight: 800;

        @media screen and (width < 720px) {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }
`;
