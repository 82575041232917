import { useSelector } from "react-redux";

export const useHeader = () => {
  const { isOpen, isBlack } = useSelector((state) => state.app.header);

  return {
    isOpen,
    isBlack,
  };
};
