import { Container } from "../../../components/Container/Container.jsx";
import { useLanguage } from "../../../hooks/useLanguage.js";
import {
  AnimaContainer,
  Content,
  Decor,
  DecorContainer,
  InfoContainer,
  List,
  MissionContainer,
  ScreenContainer,
  Section,
} from "./Hero.styled.js";
import AnimatedSVG from "./AnimatedSVG/AnimatedSVG.jsx";
import { useInView } from "react-intersection-observer";

export const Hero = () => {
  const {
    schema: {
      about: {
        hero: { title, desc, info, list },
        mission: { title: titleMission, desc: descMission },
      },
    },
  } = useLanguage();

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Section ref={ref} id="about">
      <Container>
        <ScreenContainer>
          <Content>
            <AnimaContainer>{inView && <AnimatedSVG />}</AnimaContainer>
            <h1
              className="gsap"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              {title}
            </h1>
            <p
              className="desc gsap"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              {desc}
            </p>
            <p
              className="info gsap"
              data-aos="fade-up"
              data-aos-anchor-placement="bottom-bottom"
            >
              {info}
            </p>
          </Content>
        </ScreenContainer>

        <InfoContainer>
          <DecorContainer>
            <div className="decor one">
              <Decor className="one" translateY={[0, -40]} />
            </div>
            <Decor className="two" translateY={[0, 20]} />
            <div className="decor two">
              <Decor className="three" translateY={[0, -30]} />
            </div>
          </DecorContainer>
          <List>
            {list.map(({ label, desc }, index) => {
              return (
                <li key={index} data-aos="fade-right">
                  <h3>{label}</h3>
                  <p>{desc}</p>
                </li>
              );
            })}
          </List>
        </InfoContainer>

        <MissionContainer>
          <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {titleMission}
          </h2>
          <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {descMission}
          </p>
        </MissionContainer>
      </Container>
    </Section>
  );
};
