import React from "react";
import { motion } from "framer-motion";

const AnimatedSVG = () => {
  const paths = [
    {
      targetOpacity: "0.0344828",
      path: "M657.377 249.755C657.347 253.783 655.549 257.845 651.989 261.916C648.424 265.993 643.135 270.026 636.251 273.951C622.483 281.801 602.524 289.116 577.796 295.496C528.355 308.251 460.066 317.216 384.659 319.347C309.252 321.478 241.067 316.37 191.793 306.404C167.148 301.419 147.288 295.23 133.63 288.155C126.801 284.617 121.568 280.882 118.062 277.005C114.561 273.134 112.822 269.171 112.851 265.143C112.881 261.115 114.679 257.052 118.239 252.982C121.803 248.905 127.093 244.872 133.977 240.946C147.745 233.096 167.703 225.782 192.432 219.402C241.873 206.646 310.162 197.682 385.569 195.551C460.976 193.42 529.161 198.528 578.435 208.494C603.08 213.478 622.939 219.668 636.598 226.743C643.427 230.28 648.659 234.016 652.166 237.893C655.667 241.764 657.406 245.727 657.377 249.755Z",
    },
    {
      targetOpacity: "0.0689655",
      path: "M657.553 274.995C657.17 279.02 655.027 282.926 651.135 286.685C647.237 290.45 641.631 294.021 634.451 297.346C620.09 303.996 599.629 309.575 574.513 313.806C524.296 322.264 455.694 325.3 380.623 320.889C305.551 316.479 238.287 305.461 190.228 291.228C166.191 284.11 147.013 276.203 134.07 267.948C127.599 263.821 122.73 259.634 119.588 255.456C116.451 251.284 115.07 247.171 115.453 243.146C115.836 239.12 117.979 235.215 121.871 231.456C125.769 227.691 131.375 224.12 138.555 220.794C152.916 214.144 173.377 208.566 198.493 204.335C248.71 195.876 317.311 192.841 392.383 197.251C467.454 201.662 534.719 212.68 582.778 226.912C606.815 234.031 625.993 241.938 638.936 250.192C645.407 254.32 650.276 258.506 653.418 262.685C656.555 266.857 657.936 270.969 657.553 274.995Z",
    },
    {
      targetOpacity: "0.103448",
      path: "M655.668 300.114C654.934 304.106 652.462 307.824 648.267 311.243C644.066 314.667 638.186 317.75 630.764 320.45C615.921 325.85 595.113 329.649 569.799 331.699C519.189 335.796 450.797 332.88 376.632 321.961C302.468 311.043 236.635 294.199 190.158 275.808C166.912 266.61 148.561 257.045 136.432 247.675C130.367 242.989 125.9 238.384 123.146 233.935C120.397 229.494 119.385 225.263 120.119 221.271C120.852 217.278 123.325 213.56 127.52 210.141C131.721 206.717 137.601 203.634 145.023 200.934C159.866 195.535 180.674 191.735 205.987 189.685C256.598 185.588 324.99 188.504 399.154 199.423C473.319 210.341 539.151 227.185 585.628 245.576C608.875 254.774 627.226 264.339 639.355 273.709C645.419 278.395 649.887 283.001 652.641 287.449C655.39 291.89 656.402 296.121 655.668 300.114Z",
    },
    {
      targetOpacity: "0.137931",
      path: "M651.746 324.932C650.666 328.861 647.883 332.363 643.418 335.416C638.945 338.473 632.836 341.043 625.229 343.098C610.015 347.206 589.019 349.199 563.701 349.051C513.083 348.756 445.421 339.911 372.727 322.567C300.034 305.224 236.135 282.682 191.593 260.274C169.315 249.066 151.929 237.916 140.707 227.501C135.096 222.293 131.063 217.303 128.718 212.619C126.377 207.943 125.743 203.625 126.822 199.696C127.902 195.767 130.685 192.265 135.15 189.213C139.623 186.155 145.732 183.585 153.339 181.531C168.552 177.422 189.549 175.43 214.867 175.577C265.485 175.872 333.147 184.718 405.841 202.061C478.534 219.404 542.433 241.946 586.975 264.354C609.253 275.562 626.639 286.712 637.861 297.127C643.472 302.335 647.505 307.325 649.85 312.009C652.191 316.686 652.825 321.003 651.746 324.932Z",
    },
    {
      targetOpacity: "0.172414",
      path: "M645.826 349.273C642.986 356.955 633.462 362.326 617.897 365.13C602.43 367.916 581.404 368.086 556.275 365.743C506.034 361.058 439.616 346.35 368.947 322.714C298.278 299.078 236.798 271.01 194.53 244.755C173.39 231.622 157.103 218.973 146.872 207.592C136.577 196.14 132.683 186.28 135.522 178.599C138.362 170.917 147.886 165.546 163.451 162.742C178.918 159.956 199.944 159.786 225.073 162.129C275.314 166.814 341.732 181.522 412.401 205.158C483.07 228.793 544.55 256.861 586.817 283.117C607.958 296.249 624.245 308.899 634.476 320.28C644.771 331.732 648.665 341.592 645.826 349.273Z",
    },
    {
      targetOpacity: "0.206897",
      path: "M637.964 372.964C634.469 380.398 624.537 384.939 608.837 386.39C593.232 387.833 572.338 386.179 547.589 381.659C498.106 372.62 433.439 352.161 365.332 322.413C297.225 292.665 238.633 259.284 198.96 229.381C179.118 214.424 164.053 200.372 154.892 188.112C145.674 175.776 142.669 165.584 146.164 158.151C149.659 150.717 159.591 146.176 175.292 144.725C190.896 143.282 211.79 144.935 236.539 149.456C286.022 158.495 350.69 178.953 418.797 208.702C486.904 238.45 545.495 271.831 585.168 301.734C605.011 316.69 620.075 330.743 629.236 343.003C638.454 355.339 641.459 365.531 637.964 372.964Z",
    },
    {
      targetOpacity: "0.241379",
      path: "M628.232 395.839C624.107 402.967 613.844 406.643 598.127 406.731C582.504 406.82 561.9 403.355 537.719 396.691C489.372 383.367 426.947 357.314 361.92 321.68C296.894 286.045 241.635 247.606 204.86 214.283C186.466 197.616 172.739 182.267 164.716 169.221C156.645 156.097 154.553 145.65 158.678 138.522C162.802 131.393 173.066 127.717 188.783 127.629C204.405 127.54 225.009 131.005 249.19 137.669C297.537 150.993 359.963 177.046 424.989 212.68C490.016 248.315 545.274 286.754 582.049 320.077C600.443 336.744 614.17 352.093 622.193 365.139C630.264 378.264 632.357 388.71 628.232 395.839Z",
    },
    {
      targetOpacity: "0.275862",
      path: "M616.712 417.732C611.989 424.5 601.473 427.282 585.859 426.008C570.337 424.741 550.18 419.492 526.751 410.735C479.907 393.227 420.199 361.779 358.747 320.529C297.296 279.279 245.791 236.075 212.193 199.586C195.388 181.335 183.103 164.806 176.28 151.074C169.416 137.26 168.253 126.638 172.976 119.87C177.698 113.102 188.215 110.319 203.829 111.594C219.351 112.86 239.508 118.109 262.937 126.866C309.781 144.374 369.489 175.823 430.941 217.072C492.392 258.322 543.897 301.527 577.495 338.016C594.299 356.266 606.585 372.795 613.408 386.527C620.272 400.342 621.435 410.964 616.712 417.732Z",
    },
    {
      targetOpacity: "0.310345",
      path: "M603.508 438.491C598.223 444.846 587.534 446.713 572.141 444.086C556.838 441.474 537.281 434.48 514.782 423.698C469.798 402.139 413.261 365.534 355.853 318.983C298.444 272.432 251.085 224.791 220.919 185.413C205.831 165.718 195.08 148.135 189.509 133.821C183.904 119.422 183.68 108.705 188.965 102.35C194.25 95.9946 204.939 94.1272 220.332 96.7547C235.635 99.3667 255.192 106.36 277.691 117.143C322.675 138.701 379.212 175.306 436.621 221.857C494.029 268.408 541.389 316.05 571.554 355.427C586.642 375.123 597.393 392.705 602.964 407.02C608.569 421.419 608.793 432.135 603.508 438.491Z",
    },
    {
      targetOpacity: "0.344828",
      path: "M588.725 457.972C582.918 463.866 572.139 464.804 557.083 460.844C542.115 456.907 523.307 448.222 501.909 435.495C459.128 410.05 406.192 368.567 353.263 317.069C300.335 265.572 257.481 213.856 230.977 171.889C217.721 150.899 208.587 132.396 204.309 117.609C200.006 102.734 200.723 92.0052 206.53 86.1116C212.336 80.2181 223.115 79.2795 238.171 83.2401C253.139 87.1774 271.947 95.8621 293.345 108.589C336.126 134.034 389.062 175.517 441.991 227.014C494.92 278.512 537.774 330.228 564.277 372.195C577.533 393.185 586.668 411.688 590.945 426.475C595.248 441.35 594.531 452.079 588.725 457.972Z",
    },
    {
      targetOpacity: "0.37931",
      path: "M572.501 476.036C566.217 481.422 555.43 481.425 540.826 476.162C526.308 470.929 508.391 460.619 488.257 446.045C448.004 416.908 399.072 370.862 351.026 314.809C302.979 258.757 264.957 203.36 242.317 159.124C230.994 136.998 223.545 117.716 220.594 102.568C217.625 87.3308 219.278 76.6709 225.561 71.2848C231.845 65.8986 242.632 65.8958 257.236 71.1593C271.755 76.3921 289.671 86.702 309.805 101.276C350.059 130.413 398.99 176.459 447.037 232.512C495.083 288.564 533.105 343.961 555.745 388.197C567.068 410.323 574.517 429.605 577.469 444.753C580.437 459.99 578.785 470.65 572.501 476.036Z",
    },
    {
      targetOpacity: "0.413793",
      path: "M554.944 492.565C548.232 497.402 537.519 496.469 523.477 489.943C509.518 483.454 492.629 471.597 473.913 455.288C436.493 422.679 391.938 372.421 349.14 312.24C306.341 252.059 273.44 193.402 254.836 147.233C245.531 124.141 239.825 104.226 238.223 88.8321C236.611 73.3477 239.187 62.8388 245.899 58.0016C252.611 53.1644 263.324 54.0969 277.365 60.6236C291.325 67.112 308.213 78.9687 326.929 95.2785C364.349 127.887 408.905 178.145 451.703 238.326C494.502 298.507 527.402 357.164 546.006 403.333C555.311 426.425 561.018 446.34 562.62 461.734C564.232 477.218 561.656 487.727 554.944 492.565Z",
    },
    {
      targetOpacity: "0.448276",
      path: "M536.211 507.439C529.122 511.69 518.566 509.83 505.193 502.09C491.899 494.395 476.167 481.081 459.011 463.16C424.709 427.328 384.87 373.24 347.645 309.388C310.42 245.537 282.891 184.067 268.464 136.316C261.249 112.433 257.328 92.036 257.087 76.5139C256.844 60.8997 260.324 50.6221 267.413 46.3711C274.502 42.12 285.058 43.9804 298.43 51.7207C311.724 59.4154 327.456 72.7289 344.613 90.6508C378.915 126.483 418.754 180.57 455.979 244.422C493.204 308.274 520.733 369.743 535.16 417.494C542.375 441.378 546.296 461.774 546.537 477.296C546.78 492.911 543.3 503.188 536.211 507.439Z",
    },
    {
      targetOpacity: "0.482759",
      path: "M516.454 520.559C509.044 524.191 498.724 521.418 486.122 512.522C473.595 503.68 459.139 489.011 443.673 469.613C412.75 430.83 377.929 373.325 346.562 306.288C315.194 239.252 293.245 175.437 283.106 126.467C278.035 101.974 275.93 81.2516 277.052 65.7189C278.18 50.0933 282.537 40.1259 289.948 36.4935C297.359 32.8612 307.678 35.6347 320.28 44.5301C332.808 53.3727 347.264 68.0417 362.73 87.4393C393.652 126.222 428.473 183.728 459.841 250.764C491.209 317.8 513.157 381.616 523.296 430.585C528.368 455.078 530.472 475.801 529.351 491.333C528.223 506.959 523.865 516.926 516.454 520.559Z",
    },
    {
      targetOpacity: "0.517241",
      path: "M495.837 531.838C488.161 534.824 478.157 531.159 466.421 521.176C454.756 511.253 441.686 495.34 428.028 474.614C400.72 433.176 371.183 372.689 345.911 302.978C320.639 233.268 304.438 167.592 298.664 117.777C295.775 92.8607 295.502 71.9692 297.978 56.544C300.469 41.0255 305.671 31.4447 313.347 28.4586C321.022 25.4725 331.026 29.1375 342.762 39.1208C354.428 49.0441 367.497 64.9571 381.155 85.6829C408.463 127.121 438 187.608 463.272 257.318C488.545 327.029 504.745 392.705 510.52 442.52C513.408 467.436 513.681 488.328 511.205 503.753C508.714 519.271 503.512 528.852 495.837 531.838Z",
    },
    {
      targetOpacity: "0.551724",
      path: "M474.524 541.202C466.643 543.519 457.031 538.991 446.25 527.995C435.535 517.067 423.951 500.031 412.205 478.134C388.72 434.356 364.691 371.349 345.707 299.494C326.723 227.639 316.394 160.603 315.028 110.321C314.345 85.1713 315.905 64.27 319.717 49.0697C323.552 33.776 329.559 24.6552 337.439 22.3378C345.32 20.0205 354.932 24.5485 365.713 35.5443C376.428 46.4728 388.012 63.5088 399.759 85.4051C423.244 129.184 447.272 192.191 466.256 264.046C485.24 335.9 495.57 402.936 496.936 453.219C497.619 478.368 496.058 499.269 492.246 514.47C488.411 529.763 482.405 538.884 474.524 541.202Z",
    },
    {
      targetOpacity: "0.586207",
      path: "M452.69 548.592C444.664 550.223 435.516 544.867 425.773 532.942C416.09 521.091 406.08 503.062 396.335 480.162C376.851 434.376 358.514 369.328 345.963 295.875C333.411 222.423 329.032 154.538 332.085 104.17C333.612 78.9786 336.995 58.2265 342.114 43.3668C347.264 28.4141 354.029 19.8231 362.055 18.1916C370.08 16.5602 379.228 21.9163 388.971 33.8412C398.654 45.6921 408.664 63.7215 418.409 86.6218C437.893 132.408 456.23 197.456 468.782 270.908C481.333 344.36 485.712 412.246 482.659 462.613C481.132 487.805 477.749 508.557 472.63 523.417C467.48 538.369 460.715 546.96 452.69 548.592Z",
    },
    {
      targetOpacity: "0.62069",
      path: "M430.509 553.963C426.461 554.43 422.292 553.142 418.028 550.113C413.758 547.08 409.443 542.332 405.155 535.99C396.578 523.307 388.218 504.421 380.548 480.691C365.214 433.247 352.709 366.652 346.685 292.162C340.662 217.671 342.266 149.452 349.715 99.3836C353.441 74.3413 358.621 53.8963 365.007 39.4901C368.201 32.2867 371.675 26.642 375.38 22.6899C379.08 18.7442 382.966 16.529 387.014 16.0628C391.062 15.5967 395.232 16.8841 399.495 19.9128C403.765 22.9464 408.08 27.6942 412.369 34.036C420.946 46.719 429.306 65.6048 436.975 89.3348C452.309 136.779 464.815 203.374 470.838 277.864C476.861 352.355 475.257 420.574 467.808 470.643C464.082 495.685 458.903 516.13 452.516 530.536C449.323 537.739 445.849 543.384 442.143 547.336C438.444 551.282 434.557 553.497 430.509 553.963Z",
    },
    {
      targetOpacity: "0.655172",
      path: "M408.164 557.289C404.106 557.404 400.08 555.757 396.111 552.36C392.136 548.958 388.268 543.84 384.565 537.13C377.159 523.711 370.513 504.112 364.978 479.733C353.91 430.991 347.331 363.357 347.882 288.395C348.432 213.433 356.008 145.399 367.797 96.0106C373.693 71.3083 380.63 51.326 388.236 37.483C392.039 30.5611 395.984 25.2214 400.011 21.5934C404.031 17.9713 408.083 16.096 412.141 15.9813C416.199 15.8667 420.225 17.5136 424.194 20.91C428.168 24.3119 432.037 29.4308 435.74 36.1405C443.146 49.5593 449.792 69.1578 455.327 93.537C466.395 142.28 472.974 209.913 472.423 284.875C471.872 359.837 464.296 427.871 452.508 477.26C446.612 501.962 439.675 521.944 432.069 535.787C428.266 542.709 424.321 548.049 420.294 551.677C416.274 555.299 412.222 557.174 408.164 557.289Z",
    },
    {
      targetOpacity: "0.689655",
      path: "M385.833 558.555C381.796 558.318 377.943 556.324 374.3 552.586C370.651 548.842 367.258 543.391 364.169 536.364C357.991 522.311 353.109 502.149 349.75 477.306C343.033 427.637 342.431 359.478 349.551 284.615C356.672 209.752 370.162 142.422 386.2 94.089C394.222 69.9148 402.863 50.5472 411.631 37.3728C416.015 30.7851 420.402 25.7911 424.718 22.5149C429.028 19.2438 433.215 17.7226 437.252 17.9597C441.289 18.1969 445.142 20.1905 448.785 23.9288C452.434 27.6731 455.827 33.1241 458.916 40.1508C465.094 54.2033 469.976 74.3654 473.335 99.2084C480.052 148.878 480.654 217.036 473.533 291.899C466.413 366.762 452.923 434.093 436.885 482.426C428.863 506.6 420.222 525.968 411.454 539.142C407.07 545.73 402.683 550.724 398.367 554C394.057 557.271 389.869 558.792 385.833 558.555Z",
    },
    {
      targetOpacity: "0.724138",
      path: "M363.696 557.764C359.711 557.177 356.061 554.852 352.771 550.8C349.475 546.742 346.584 541 344.132 533.71C339.229 519.131 336.149 498.558 334.992 473.441C332.677 423.222 338.056 355.058 351.693 280.864C365.33 206.669 384.631 140.555 404.797 93.6455C414.883 70.1833 425.163 51.5779 435.026 39.1724C439.958 32.9691 444.752 28.3589 449.326 25.4593C453.893 22.5643 458.184 21.4083 462.169 21.995C466.154 22.5817 469.804 24.9066 473.094 28.9584C476.389 33.0164 479.281 38.7582 481.733 46.0485C486.636 60.6278 489.716 81.2001 490.873 106.318C493.187 156.536 487.809 224.701 474.172 298.895C460.535 373.089 441.234 439.204 421.068 486.113C410.982 509.575 400.702 528.181 390.839 540.586C385.907 546.789 381.113 551.4 376.538 554.299C371.971 557.194 367.681 558.35 363.696 557.764Z",
    },
    {
      targetOpacity: "0.758621",
      path: "M341.935 554.933C338.032 554.002 334.612 551.363 331.699 547.029C328.783 542.688 326.415 536.699 324.619 529.201C321.028 514.206 319.774 493.38 320.827 468.179C322.933 417.793 334.252 350.142 354.301 277.181C374.35 204.22 399.315 139.825 423.456 94.6961C435.53 72.1245 447.371 54.423 458.254 42.881C463.696 37.1094 468.862 32.918 473.659 30.4172C478.448 27.9202 482.808 27.1381 486.711 28.0693C490.614 29.0005 494.034 31.6387 496.947 35.9732C499.863 40.3143 502.231 46.3031 504.027 53.8014C507.618 68.7966 508.872 89.6225 507.819 114.823C505.713 165.209 494.394 232.86 474.345 305.821C454.296 378.782 429.33 443.178 405.19 488.306C393.116 510.878 381.275 528.579 370.392 540.121C364.949 545.893 359.784 550.084 354.987 552.585C350.198 555.082 345.838 555.864 341.935 554.933Z",
    },
    {
      targetOpacity: "0.793103",
      path: "M320.723 550.097C316.932 548.829 313.767 545.897 311.255 541.313C308.74 536.722 306.913 530.532 305.787 522.883C303.536 507.586 304.117 486.665 307.373 461.572C313.883 411.403 331.057 344.78 357.365 273.608C383.674 202.436 414.114 140.249 442.045 97.2451C456.015 75.736 469.328 59.0732 481.148 48.4825C487.059 43.1867 492.556 39.446 497.539 37.3629C502.515 35.283 506.911 34.8803 510.703 36.1485C514.495 37.4167 517.659 40.3482 520.171 44.9323C522.687 49.5234 524.514 55.7136 525.639 63.3629C527.89 78.66 527.31 99.5809 524.054 124.673C517.544 174.842 500.37 241.466 474.062 312.638C447.753 383.81 417.313 445.996 389.381 489.001C375.411 510.51 362.099 527.172 350.279 537.763C344.368 543.059 338.87 546.8 333.887 548.883C328.912 550.963 324.515 551.365 320.723 550.097Z",
    },
    {
      targetOpacity: "0.827586",
      path: "M300.235 543.306C296.583 541.711 293.698 538.508 291.605 533.709C289.51 528.903 288.238 522.559 287.791 514.817C286.897 499.334 289.308 478.478 294.742 453.684C305.607 404.114 328.505 339.026 360.873 270.184C393.241 201.343 428.924 141.839 460.434 101.286C476.194 81.0032 490.875 65.506 503.543 55.9475C509.878 51.1677 515.666 47.9063 520.797 46.2568C525.92 44.6098 530.32 44.5893 533.972 46.1845C537.624 47.7796 540.509 50.982 542.602 55.781C544.697 60.587 545.969 66.9316 546.416 74.6737C547.31 90.156 544.899 111.013 539.465 135.806C528.6 185.376 505.702 250.465 473.334 319.306C440.966 388.148 405.283 447.652 373.773 488.204C358.013 508.487 343.331 523.984 330.663 533.543C324.329 538.323 318.541 541.584 313.41 543.233C308.287 544.88 303.887 544.901 300.235 543.306Z",
    },
    {
      targetOpacity: "0.862069",
      path: "M280.635 534.621C277.15 532.711 274.566 529.262 272.909 524.285C271.249 519.301 270.543 512.85 270.778 505.074C271.248 489.524 275.471 468.891 283.042 444.586C298.179 395.992 326.626 332.933 364.807 266.947C402.989 200.96 443.644 144.591 478.492 106.799C495.922 87.8969 511.861 73.6834 525.28 65.2299C531.99 61.0026 538.024 58.2454 543.264 57.0421C548.497 55.8406 552.867 56.2021 556.352 58.1118C559.837 60.0215 562.42 63.4705 564.078 68.4477C565.737 73.4322 566.443 79.8829 566.208 87.6587C565.739 103.208 561.515 123.842 553.944 148.147C538.808 196.741 510.36 259.799 472.179 325.786C433.998 391.773 393.343 448.142 358.494 485.934C341.065 504.836 325.125 519.05 311.706 527.503C304.996 531.73 298.962 534.488 293.722 535.691C288.489 536.892 284.119 536.531 280.635 534.621Z",
    },
    {
      targetOpacity: "0.896552",
      path: "M262.085 524.123C258.793 521.913 256.53 518.244 255.321 513.126C254.109 508.001 253.974 501.494 254.889 493.743C256.719 478.245 262.724 457.991 272.373 434.359C291.667 387.111 325.448 326.563 369.151 263.933C412.855 201.303 458.172 148.499 496.094 113.755C515.061 96.3778 532.137 83.5563 546.205 76.2723C553.239 72.6298 559.474 70.3978 564.783 69.6498C570.085 68.9029 574.392 69.6434 577.683 71.853C580.975 74.0626 583.238 77.7321 584.448 82.8498C585.659 87.9748 585.794 94.4824 584.879 102.233C583.049 117.731 577.045 137.985 567.395 161.617C548.101 208.865 514.321 269.413 470.617 332.043C426.913 394.673 381.596 447.477 343.674 482.221C324.707 499.598 307.631 512.42 293.564 519.704C286.529 523.346 280.295 525.578 274.985 526.326C269.683 527.073 265.377 526.333 262.085 524.123Z",
    },
    {
      targetOpacity: "0.931035",
      path: "M244.735 511.904C241.661 509.412 239.735 505.549 238.983 500.33C238.23 495.104 238.666 488.588 240.254 480.923C243.431 465.593 251.17 445.874 262.826 423.095C286.129 377.553 324.986 319.976 373.88 261.18C422.774 202.383 472.408 153.546 513.114 122.115C533.474 106.394 551.557 95.0625 566.166 89.0035C573.472 85.9736 579.859 84.2838 585.197 83.9968C590.528 83.7103 594.739 84.8239 597.813 87.3166C600.887 89.8093 602.813 93.6718 603.565 98.8909C604.318 104.117 603.882 110.632 602.294 118.298C599.117 133.628 591.378 153.347 579.722 176.125C556.419 221.668 517.562 279.244 468.668 338.041C419.774 396.837 370.14 445.675 329.434 477.106C309.074 492.827 290.991 504.158 276.382 510.217C269.076 513.247 262.689 514.937 257.351 515.224C252.02 515.511 247.809 514.397 244.735 511.904Z",
    },
    {
      targetOpacity: "0.965517",
      path: "M228.729 498.068C225.895 495.311 224.322 491.285 224.033 486.004C223.743 480.716 224.747 474.243 226.997 466.72C231.496 451.677 240.911 432.642 254.483 410.89C281.62 367.4 325.257 313.233 378.969 258.718C432.68 204.203 486.254 159.704 529.435 131.825C551.033 117.881 569.984 108.125 585.024 103.337C592.545 100.943 599.036 99.8081 604.362 99.9843C609.681 100.16 613.765 101.638 616.599 104.395C619.433 107.152 621.006 111.179 621.295 116.459C621.585 121.748 620.581 128.22 618.331 135.743C613.832 150.787 604.417 169.821 590.845 191.573C563.708 235.064 520.071 289.23 466.359 343.746C412.648 398.261 359.074 442.76 315.893 470.639C294.295 484.583 275.344 494.339 260.304 499.127C252.783 501.521 246.292 502.656 240.966 502.479C235.647 502.303 231.563 500.825 228.729 498.068Z",
    },
    {
      targetOpacity: "1",
      path: "M214.2 482.734C211.627 479.733 210.418 475.574 210.594 470.271C210.77 464.962 212.335 458.581 215.229 451.258C221.016 436.615 232.035 418.41 247.421 397.85C278.183 356.743 326.269 306.399 384.39 256.58C442.51 206.76 499.616 166.939 544.943 142.824C567.614 130.763 587.29 122.657 602.645 119.177C610.324 117.437 616.87 116.866 622.144 117.504C627.411 118.141 631.337 119.972 633.909 122.973C636.481 125.974 637.69 130.134 637.515 135.436C637.339 140.746 635.774 147.127 632.879 154.449C627.092 169.092 616.073 187.297 600.687 207.857C569.925 248.964 521.84 299.308 463.719 349.128C405.598 398.947 348.493 438.769 303.165 462.883C280.494 474.945 260.819 483.05 245.463 486.53C237.784 488.27 231.238 488.841 225.964 488.203C220.698 487.566 216.772 485.735 214.2 482.734Z",
    },
  ];

  return (
    <motion.svg
      width="799"
      height="673"
      viewBox="0 0 799 673"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        {paths.map(({ targetOpacity, path }, index) => (
          <motion.path
            key={index}
            d={path}
            stroke="#212121"
            fill="none"
            strokeWidth="1"
            initial={{ opacity: 0, rotate: index * -5 }}
            animate={{ opacity: parseFloat(targetOpacity), rotate: 0 }}
            transition={{ duration: 2, delay: index * 0.005 }}
          />
        ))}
      </g>
    </motion.svg>
  );
};

export default AnimatedSVG;
