import { useEffect } from "react";
import { Container } from "../../components/Container/Container.jsx";
import { useLanguage } from "../../hooks/useLanguage.js";
import { Content, FormContainer, Section } from "./Contact.styled.js";
import { ContactForm } from "../../forms/contact/ContactForm.jsx";
import { useDispatch } from "react-redux";
import { useInView } from "react-intersection-observer";
import { setSection } from "../../redux/app/slice.js";

const Contact = () => {
  const {
    schema: {
      contact: { title, desc },
    },
  } = useLanguage();
  const dispatch = useDispatch();

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    inView && dispatch(setSection("contact"));
  }, [dispatch, inView]);

  return (
    <Section ref={ref} id="contact">
      <Container>
        <Content>
          <h2 data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {title}
          </h2>
          <p data-aos="fade-up" data-aos-anchor-placement="bottom-bottom">
            {desc}
          </p>
        </Content>
        <FormContainer
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <ContactForm />
        </FormContainer>
      </Container>
    </Section>
  );
};

export default Contact;
