import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  z-index: 10;
`;

export const LinkStyled = styled.button`
  text-transform: uppercase;
  background-color: transparent;
  color: #fff;

  transition: all var(--transition);
  display: block;
  border: none;
  width: 100%;
  overflow: hidden;
  border-radius: 12px;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0 0 5px 1px rgba(0, 0, 0, 0.2);
    }
  }

  & p {
    white-space: nowrap;
    z-index: 5;
    font-weight: 400;
    font-size: 19px;

    @media screen and (width < 720px) {
      font-size: 16px;
    }
  }

  @media screen and (width < 720px) {
    font-size: 14px;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background: linear-gradient(90deg, #d761a5 0%, #55a4e6 100%);
  padding: 15px 30px;

  @media screen and (width < 720px) {
    padding: 12px 20px;
  }

  & svg {
    fill: currentColor;
  }
`;
