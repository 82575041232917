import { useCallback, useEffect, useState } from "react";
import { useLanguage } from "../../hooks/useLanguage.js";
import { Form, RowContainer, SubmitButton } from "./ContactForm.styled.js";
import { CustomInput } from "../../components/CustomInput/CustomInput.jsx";
import { CustomPhoneNumberInput } from "../../components/CustomPhoneNumberInput/CustomPhoneNumberInput.jsx";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { svg } from "../../img/index.js";
import emailjs from "@emailjs/browser";

const schema = Yup.object().shape({
  name: Yup.string().trim().required("required"),
  last_name: Yup.string().trim(),
  email: Yup.string().email("Invalid email format").required("required"),
  address: Yup.string().trim().required("required"),
  country: Yup.string().trim().required("required"),
  telegram_id: Yup.string().trim().required("required"),
  phone: Yup.string().min(11).max(15),
});

export const ContactForm = ({ className }) => {
  const {
    schema: {
      contact: {
        form: { sign_up, contact_us_now },
      },
    },
  } = useLanguage();
  const [values, setValues] = useState({});
  const [isSubmitting, setSubmitting] = useState(false);
  const [isValid, setValid] = useState(false);

  useEffect(() => {
    schema
      .validate(values)
      .then(() => setValid(true))
      .catch(() => setValid(false));
  }, [values]);

  const setFieldValue = useCallback((field, value) => {
    setValues((prev) => ({ ...prev, [field]: value }));
  }, []);

  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!isValid || isSubmitting) return;
      const toastId = toast.loading("Loading...");
      setSubmitting(true);
      emailjs
        .send("service_7l14waj", "template_786cqdc", values, {
          publicKey: "Fq-g1emeKRUMoBZOF",
        })
        .then(() => {
          toast.success("Sent", {
            id: toastId,
            duration: 6000,
          });
          setValues({});
        })
        .catch((error) => {
          console.log(error);
          toast.error(error.response?.data?.message, { id: toastId });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
    [isSubmitting, isValid, values]
  );

  return (
    <Form className={className} onSubmit={onSubmit} id="contact-form">
      <h3>{sign_up}</h3>
      <RowContainer>
        <CustomInput
          label={"NAME/COMPANY"}
          type="text"
          name={"name"}
          id={"name"}
          isRequired={true}
          value={values.name}
          onChange={({ target: { value } }) => setFieldValue("name", value)}
        />
        <CustomInput
          label={"LAST NAME"}
          type="text"
          name={"last_name"}
          id={"last_name"}
          isRequired={false}
          value={values.last_name}
          onChange={({ target: { value } }) =>
            setFieldValue("last_name", value)
          }
        />
      </RowContainer>

      <CustomInput
        label={"EMAIL"}
        type="email"
        name={"email"}
        id={"email"}
        isRequired={true}
        value={values.email}
        onChange={({ target: { value } }) => setFieldValue("email", value)}
      />

      <RowContainer>
        <CustomInput
          label={"ADDRESS"}
          type="text"
          name={"address"}
          id={"address"}
          isRequired={true}
          value={values.address}
          onChange={({ target: { value } }) => setFieldValue("address", value)}
        />
        <CustomInput
          label={"COUNTRY"}
          type="text"
          name={"country"}
          id={"country"}
          isRequired={true}
          value={values.country}
          onChange={({ target: { value } }) => setFieldValue("country", value)}
        />
      </RowContainer>

      <RowContainer>
        <CustomInput
          label={"TELEGRAM ID"}
          type="text"
          name={"telegram_id"}
          id={"telegram_id"}
          isRequired={true}
          value={values.telegram_id}
          onChange={({ target: { value } }) =>
            setFieldValue("telegram_id", value)
          }
        />
        <CustomPhoneNumberInput
          id="phone"
          label="PHONE NUMBER"
          name="phone"
          isRequired={true}
          value={values.phone}
          onChange={(value) => setFieldValue("phone", value)}
        />
      </RowContainer>

      <SubmitButton type="submit" disabled={!isValid || isSubmitting}>
        <p>{contact_us_now}</p>
        <svg width={20} height={20}>
          <use href={`${svg}#icon-mail`} />
        </svg>
      </SubmitButton>
    </Form>
  );
};
