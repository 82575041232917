import { InputContainer, InputLabel, InputStyled } from "./CustomInput.styled";

export const CustomInput = ({
  label,
  type = "text",
  placeholder,
  name,
  id,
  value = "",
  onChange = () => {},
  isRequired = false,
}) => {
  return (
    <InputContainer>
      <InputStyled
        onChange={onChange}
        type={type}
        placeholder={placeholder}
        name={name}
        id={id}
        value={value}
      />
      {label && (
        <InputLabel htmlFor={id}>{`${label}${
          isRequired ? " *" : ""
        }`}</InputLabel>
      )}
    </InputContainer>
  );
};
