import styled from "styled-components";

export const HeaderStyled = styled.header`
  width: 100%;
  background-color: transparent;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  grid-area: header;
  transition: all var(--transition);

  &.hidden {
    top: -78px;

    @media screen and (width < 720px) {
      top: -50px;
    }
  }

  &.scroll,
  &.burger-open {
    box-shadow: 0px 0px 8px 1px rgb(0 0 0 / 10%);
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
    background-color: rgba(255, 255, 255, 0.5);
  }

  &.black {
    & .container {
      & .nav-menu {
        & button {
          color: var(--body-background);
          transition: all var(--transition);

          &:is(:hover, .active) {
            background-color: var(--body-background);
            color: var(--color);
          }
        }
      }
    }
  }

  &.scroll,
  &.burger-open {
    color: var(--color);
  }

  .container {
    min-height: 78px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px 30px;

    @media screen and (width < 720px) {
      min-height: 50px;
    }

    & .header-nav {
      font-size: 16px;
      @media screen and (width < 1280px) {
        display: none;
      }

      & .active {
        color: var(--accent);
        pointer-events: none;
        text-shadow: 0 0 2px rgba(255, 255, 255, 0.25);
      }
    }

    & .nav-menu {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      align-items: center;
      gap: 40px;
      transition: all var(--transition);

      @media screen and (width < 720px) {
        gap: 10px;
      }

      & button {
        text-transform: uppercase;
        font-weight: 500;
        padding: 5px 30px;
        transition: all var(--transition);
        color: var(--color);
        border-radius: 4px;
        width: 100%;

        @media screen and (width < 720px) {
          padding: 5px 10px;
          font-size: 14px;
        }

        &:is(:hover, .active) {
          background-color: var(--color);
          color: var(--body-background);
        }
      }
    }
  }
`;

export const BurgerButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  padding: 4px;
  width: 30px;
  height: 30px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 6px;

  & span {
    background: #fff;
    border-radius: 10px;
    height: 2px;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);

    &:nth-of-type(1) {
      width: 50%;
    }

    &:nth-of-type(2) {
      width: 100%;
    }

    &:nth-of-type(3) {
      width: 75%;
    }
  }

  &.is-open {
    & span {
      &:nth-of-type(1) {
        transform-origin: bottom;
        transform: rotatez(45deg) translate(4px, 1px);
      }

      &:nth-of-type(2) {
        transform-origin: top;
        transform: rotatez(-45deg);
      }

      &:nth-of-type(3) {
        transform-origin: bottom;
        width: 50%;
        transform: translate(9px, -5px) rotatez(45deg);
      }
    }
  }

  &.black {
    & span {
      background-color: #000;
    }
  }

  @media screen and (width >= 1280px) {
    display: none;
  }
`;
