import { useDispatch } from "react-redux";
import { Hero } from "./Hero/Hero.jsx";
import { Meet } from "./Meet/Meet.jsx";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { setSection } from "../../redux/app/slice.js";
import { HomeContainer } from "./Home.styled.js";

const Home = () => {
  const dispatch = useDispatch();

  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.3,
  });

  useEffect(() => {
    inView && dispatch(setSection("home"));
  }, [dispatch, inView]);

  return (
    <HomeContainer ref={ref}>
      <Hero />
      <Meet />
    </HomeContainer>
  );
};

export default Home;
